import React, { lazy, Suspense } from 'react';
import Routes from './routes';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import themeSettings from './theme/ThemeSettings';
import RTL from './theme/RTL';
import INIT_STATE from 'shared/globalTheme/themeConfig';
import './App.css';
import Loading from 'shared/components/loading';
function App() {
  // eslint-disable-next-line new-cap
  const theme = themeSettings();
  const [loading, setLoading] = React.useState(true);
  setTimeout(() => {
    setLoading(false);
  }, 3000);

  if (loading) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
        <Loading />;
      </div>
    )
  }

  return (
    <>
      <Suspense fallback={<Loading />}>
        <ThemeProvider theme={theme}>
          <RTL direction={INIT_STATE.activeDir}>
            <CssBaseline />
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </RTL>
        </ThemeProvider>
      </Suspense>
    </>
  );
}

export default App;
